<template>
  <div class="job">
    <titles></titles>
    <div class="banner">
      <div class="banner_text">
        <div class="banner_text_box">
          <h3>招聘服务外包RPO</h3>
          <p>帮助企业完成部分或全部员工招聘工作</p>
          <p>提升企业招聘质量 优化招聘周期 改善整体行政流程</p>
          <div class="banner_btn"><a href="https://affim.baidu.com/unique_28438292/chat?siteId=13950180&userId=28438292&siteToken=22dd0d31e1f7a80559410d71206df7db" target="_blank">立即咨询</a></div>
        </div>
      </div>
      <img src="@/assets/job_banner.jpg" />
    </div>

    <div class="pay_one">
      <div class="all_box">
        <div class="pay_one_title">
          <textTitle
            :cnTitle="txt.oneCnTitle"
            :enTitle="txt.oneEnTitle"
          ></textTitle>
        </div>
        <div class="pay_one_box">
          <div class="pay_one_body">
            <div class="job_one_list">
              <div class="job_one_item">
                <img src="@/assets/job01.png" />
                <h3>批量招聘服务</h3>
                <p>
                  批量招聘服务是一种面向基层岗位人才，在最短时间内找到最多、最合适人才的招聘方式，以满足企业招聘大量具备相似技能候选人的需求。
                </p>
              </div>
              <div class="job_one_item">
                <img src="@/assets/job02.png" />
                <h3>猎聘服务</h3>
                <p>
                  针对专业从事中高端管理人才和技术人才的猎头业务，覆盖金融、房产、化工医药、制造业、消费品等行业。
                </p>
              </div>
              <div class="job_one_item">
                <img src="@/assets/job03.png" />
                <h3>短期用工</h3>
                <p>
                  针对企业的特定需求，为中短期空缺岗位提供一站式短期用工解决方案，提供人员招聘、管理、绩效考核、退回人员替换等服务，
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="henfu_po">
      <henfu></henfu>
    </div>
    <div class="job_two">
      <div class="all_box">
        <div class="job_two_body">
          <div class="job_two_title">
            <textTitle
              :cnTitle="txt.twoCnTitle"
              :enTitle="txt.twoEnTitle"
            ></textTitle>
          </div>
          <div class="job_two_list">
            <div class="job_two_item">
              <div class="job_two_item_num">01</div>
              <div class="job_two_item_text">
                招聘大批量人员，帮助企业节约招聘的时间和成本
              </div>
            </div>
            <div class="job_two_item">
              <div class="job_two_item_num">02</div>
              <div class="job_two_item_text">
                为企业搜寻到符合岗位要求的候选人，提高应聘者整体质量
              </div>
            </div>
            <div class="job_two_item">
              <div class="job_two_item_num">03</div>
              <div class="job_two_item_text">制定有竞争力的招聘战略与计划</div>
            </div>
            <div class="job_two_item">
              <div class="job_two_item_num">04</div>
              <div class="job_two_item_text">提高企业雇主品牌管理质量</div>
            </div>
            <div class="job_two_item">
              <div class="job_two_item_num">05</div>
              <div class="job_two_item_text">
                减轻HR负担，使HR管理者能够关注核心管理职能
              </div>
            </div>
            <div class="job_two_item">
              <div class="job_two_item_num">06</div>
              <div class="job_two_item_text">
                为企业提供行业人才流动和发展趋势的咨询
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <check :type="2" @gotoContact="gotoContact($event)"></check>
    <foots @navChange = "navChange"></foots>
  </div>
</template>
<script>
import check from "@/components/check.vue";
import titles from "@/components/titles.vue";
import foots from "@/components/foots.vue";
import henfu from "@/components/henfu.vue";
import textTitle from "@/components/textTitle.vue";
export default {
  name: "Job",
  components: {
    titles,
    foots,
    henfu,
    textTitle,
    check
  },
  data() {
    return {
      txt: {
        oneCnTitle: "服务内容",
        oneEnTitle: "service content",
        twoCnTitle: "服务优势",
        twoEnTitle: "Service advantages",
      },
    };
  },
  mounted(){
    window.scrollTo(0, 0);
  },
  methods: {
    //跳转到contact 并查询
    gotoContact(e) {
      this.$router.push({
        path: "/contact",
        query: {
          sCode: e.sCode,
          qCode: e.qCode,
          compname: e.compname,
        },
      });
    },
    navChange(){
      debugger
      window.scrollTo(0, 0);
    }
  },
};
</script>
<style lang="scss" scoped>
.job_two_title{
    padding: 100px 0;
}
.job_two{
    padding-bottom: 120px;
}
.job_two_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .job_two_item {
    background: #f8f3eb;
    width: 32%;
    margin: 23px 0;
    padding: 50px 0;
    position: relative;
    .job_two_item_num {
      position: absolute;
      left: 0;
      top: -20px;
      font-size: 48px;
      font-weight: bold;
      font-style: italic;
      color: #c49755;
      border-bottom: 6px solid #c49755;
      //    font-style:oblique;
    }
    .job_two_item_text {
      font-size: 14px;
      color: #3e3e3e;
    }
  }
}

.job_one_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .job_one_item {
    width: 32%;
    background: #ffffff;
    padding: 65px 50px;
    box-sizing: border-box;
    border-radius: 10px;
    h3 {
      margin: 0;
      font-size: 18px;
      color: #b59167;
      margin: 18px 0 30px;
    }
    p {
      text-align: left;
      line-height: 24px;
      color: #333333;
      font-size: 14px;
    }
  }
}

.pay_one_title {
  padding: 100px 0;
}
.pay_one {
  background: #f2f2f2;
}
.pay_one_box {
  position: relative;
  height: 280px;
}
.pay_one_body {
  position: absolute;
}
.banner_btn {
  a{
    color: #fff;
    display: block;
    text-decoration: none;
  }
  font-size: 16px;
  width: 140px;
  height: 40px;
  border-radius: 15px;
  text-align: center;
  color: #fff;
  background: #d70035;
  line-height: 40px;
  margin-top: 90px;
}
.banner {
  position: relative;
  .banner_text {
    position: absolute;
    width: 100%;
    text-align: left;
    top: 50%;
    margin-top: -100px;
  }
  .banner_text_box {
    width: 80%;
    margin: 0 auto;
    h3 {
      font-size: 55px;
      color: #fff;
      margin: 0;
      margin-bottom: 32px;
    }
    p {
      font-size: 24px;
      color: #fff;
      margin: 0;
      margin: 12px 0;
      span {
        color: #d70035;
      }
    }
  }
}
</style>